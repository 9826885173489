import { WSysPage, WSysPage0, WSysPageProps, useWSysPage } from "@coimbra-its/websys-ux-core";
import { Button, Box, VStack, ButtonProps, Icon, useColorModeValue, BoxProps, Text, TextProps, FormLabel, FormLabelProps, Heading, StackProps } from '@chakra-ui/react'
import { APPICONS } from "../Icons";
import { ReactNode } from "react";
import { BigNumberish, formatUnits } from "ethers";


const sunBorderColor = '#D6D6D6';
const sunBorder = {
    border: `2px solid ${sunBorderColor}`,
    borderRadius: '48px',
    paddding: '4px',
    minHeight: '48px',
}
const sunGray = '#646464'

export function dispAddress(a: string) {
    if (!a || typeof a != 'string')
        return '[n/a]';
    if (!a.startsWith('0x'))
        a = '0x' + a;
    return a.substring(0, 9) + ' ... ' + a.slice(-8);
}


export function dispYearMonth(d: number) {
    const a = d+'';
    return a.substring(0, 4) + ' / ' + a.slice(-2);
}

export function formatAmount(a : BigNumberish, i=18) {
    const x = Number(a) / 1e18;
    const egesz = Math.floor(x);
    const frac = (Math.round((x-egesz) * 1e4) / 1e4);
    return egesz.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + (frac != 0 ? '.' + frac.toString().substring(2) : '');
}

export function extractTxError(a : string) {
    const rx = /reason=\"(.*?)\"/g;
    const found = a.match(rx);
    console.log('found', found);
    if (found && found?.length>0) {
        let msg = found[0];
        msg = msg.replace('reason="', '');
        if (msg.endsWith('"'))
            msg = msg.substring(0, msg.length-1);
        return msg;
    }
    return a;
}


// ===========================================================
// -----------------------  STACK   ---------------------------
// ===========================================================

export function SunStack({...rest} : StackProps) {
    return <VStack alignItems='stretch' justifyContent='center' h='100%' gap={9} px='4em' {...rest} />
}

// ===========================================================
// -----------------------  PAGE   ---------------------------
// ===========================================================



export function SunPage({ children, page, tools, ...rest }: WSysPageProps) {
    const bgColor = useColorModeValue('hsla(0,0%,100%,.9)', 'var(--chakra-colors-chakra-body-bg)');

    return <WSysPage page={page} bg='transparent' 
        vertMode="scroll"
        {...rest}
        headerTitle={
            <Box flex='1' textAlign='center'>
                <Heading as='h1' color='hsla(0,0%,100%,.8)' fontWeight='600' fontSize='3xl'
                >{page.propsIn.title}</Heading>
            </Box>}
            tools={tools || <>&nbsp;</>}
    >
        <Box bg={bgColor} p={2} borderRadius='lg' minH='50vh' >
            {children}
        </Box>
    </WSysPage>
}

export function SunPage0({ children, page, tools, ...rest }: WSysPageProps) {
    const bgColor = useColorModeValue('hsla(0,0%,100%,.9)', 'var(--chakra-colors-chakra-body-bg)');

    return <WSysPage0 page={page} bg='transparent' 
        {...rest}
        headerTitle={
            <Box flex='1' textAlign='center'>
                <Heading as='h1' color='hsla(0,0%,100%,.8)' fontWeight='600' fontSize='3xl'
                >{page.propsIn.title}</Heading>
            </Box>}
            tools={tools || <>&nbsp;</>}
    >
        <Box bg={bgColor} p={2} borderRadius='lg' minH='50vh' >
            {children}
        </Box>
    </WSysPage0>
}




// ===========================================================
// ----------------------- LINK BUTTON -----------------------
// ===========================================================


export interface SunLinkButtonProps extends ButtonProps {

}

export function SunLinkButton({ children, ...rest }: SunLinkButtonProps) {

    const color = '#1b1b1b';

    return <Button
        variant='link' size='md'
        {...sunBorder}
        color={color}
        justifyContent='space-between'
        p='4px'

        leftIcon={
            <Box
                display='flex' alignItems='center' justifyContent='center'
                width='48px' height='48px'
                borderRadius='24px'
                bg='linear-gradient(96.55deg,#e92424 -49.33%,#fec261 158.4%)'
                color='#fff'
            >
                <Icon as={APPICONS.Edit} />
            </Box>}

        rightIcon={<Icon as={APPICONS.Right}
            color={sunGray}
            mr='10px'
        />}

        {...rest}
    ><Box flex='1' textAlign='left' pl={4}>
            {children}
        </Box>
    </Button>
}


// ================================================================
// -----------------------  ROUNDED BOX ---------------------------
// ================================================================

export interface SunBlockProps extends BoxProps {
    header?: ReactNode;
}

export function SunBlock({ children, header, ...rest }: SunBlockProps) {
    return <Box>
        {header && <Box
            color={sunGray} fontWeight='600'
        >{header}</Box>}
        <Box
            {...sunBorder}
            display='flex' flexDir='row' alignItems='center'
            justifyContent='center'
            px={2}
            {...rest}
        >

            {children}


        </Box>
    </Box>
}


export interface SunValueProps extends TextProps {
    dim?: ReactNode;
}

export function SunValue({ children, dim, ...rest }: SunValueProps) {
    return <Box whiteSpace='nowrap'>
        <Text as='span' flexDir='row'
            fontWeight='bold' fontSize='large'
            {...rest}>
            {children}
        </Text>
        {dim && <Text as='span' display='inline-block' px={2}  >{dim}</Text>}
    </Box>
}



// ===========================================================
// ----------------------- BUTTON -----------------------
// ===========================================================


export interface SunButtonProps extends ButtonProps {
    variant?: 'primary'|'black'|'outline'
}

export function SunButton({ children, variant='primary', ...rest }: SunButtonProps) {
    const sunBg='linear-gradient(96.55deg,#e92424 -49.33%,#fec261 158.4%)';

    return <Button {...rest} 
        borderRadius='30px'
        {...variant==='primary' && {variant:'solid', colorScheme:"orange", bg:sunBg, _hover:{bg:sunBg, color:'#000'}}}
        {...variant==='black' && {variant:'solid', bg:"#555", colorScheme:'black', _hover:{bg:'#333'}}}
        
    >{children}</Button>

}

