import { AppTheme, STORE_REF, WSysPath } from "@coimbra-its/websys-ux-core"
import * as ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { App } from "./App"
import * as serviceWorker from "./serviceWorker"
import { store } from "./store/store"
import { APPICONS } from "./Icons"
import { ToastProvider, theme } from "@chakra-ui/react"


const preferencesCompanyDefault = `{"darkMode":false,"baseColor":"#df8c24","selectionColor":"#ffffcc","modeMain":"POPUPS","modeAttach":"COLUMNS","modePopup":"POPUPS","layoutAlign":"CENTER","colors":{"theme1":{"value":"#df8c24"},"theme2":{"value":"#df8c24"},"menu":{"value":"#de8a21","derived":{"method":"SHADE","color1":"theme1","p1":50}},"tableHead":{"value":"#f8e8d3","derived":{"method":"SHADE","color1":"theme1","p1":10}}}}`;
const chakraTheme = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
	colors: {
		wsysLine: 'transparent',
	},
	fonts: {
		body: `montserrat`,
	},
	styles: {
		global: {
			'body': {
				backgroundImage: 'url(images/bg.png)',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
			}
		}
	},
	components: {
		Button: {
			variants: {
				wsysHeadIconButton: (props:any) => ({
					...theme.components.Button.variants!.link(props),
					color:'#fff'
				})
			}
		}


	}
};



const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

STORE_REF.current = store as any;

root.render(
	<Provider store={store}>
			<WSysPath>
				<AppTheme icons={APPICONS} preferencesCompanyDefault={preferencesCompanyDefault} appCustomChakraTheme={chakraTheme} >
					<App />
					<ToastProvider />

				</AppTheme>
			</WSysPath>
	</Provider>
	,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

