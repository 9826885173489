import * as immer from 'immer'; // DO NOT REMOVE! 
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';


export type TWSysLayoutPageMode = 'LASTONLY' | 'COLUMNS' | 'POPUPS';




export const TWSysColorDeriveMethods = ['DIRECT', 'SHADE'] as const;
export type TWSysColorDeriveMethod = typeof TWSysColorDeriveMethods[number];

export interface WSysColorValue {
	value: string;
	derived? : {
		method: TWSysColorDeriveMethod;
		color1?: string;
		color2?: string;
		p1?: number;
	};
}

export interface WSysColors {
	theme1 : WSysColorValue;
	theme2 : WSysColorValue;
	menu : WSysColorValue;
	tableHead : WSysColorValue;

}

export type TWSysColor = keyof WSysColors;

export interface IWSysCorePreferences {

	darkMode : boolean;

	baseColor: string;
	selectionColor: string;

	colors: WSysColors;

	/*secondaryColor: string;
	selectionColor: string;
	mutationColor: string;
	contrast: keyof IBG_PATTERN;*/

	modeMain: TWSysLayoutPageMode;
	modePopup: TWSysLayoutPageMode;
	modeAttach: TWSysLayoutPageMode;

	layoutAlign: 'LEFT'|'CENTER';
}


export interface IWSysCoreLayoutState {
	showPreferences: boolean,
	showDebug: boolean,

	dumpPath: boolean;
	dumpLayout: boolean,
	dumpLayoutCalc: boolean,
	dumpFormTokens: boolean,
	/*dumpClick: boolean,
	dumpPanels: boolean,
	dumpPanelsScroll: boolean,
	dumpAutocomplete: boolean,
	dumpTable: boolean,
	dumpForm: boolean,*/
}


export interface IWSysCoreState {
	layout: IWSysCoreLayoutState;
	preferences: IWSysCorePreferences;
	unsavedforms: { url: string }[];
}

let savedPreferences: Partial<IWSysCorePreferences> = {}
try {
	savedPreferences = JSON.parse(window.localStorage.getItem('PREFERENCES') as any || {}) as any;
} catch (ex) {
	console.error(ex);
}
let savedState: Partial<IWSysCoreLayoutState> = {}
try {
	savedState = JSON.parse(window.localStorage.getItem('UX_STATE') || "{}") as any;
} catch (ex) {
	console.error(ex);
}

console.log(savedState);

const initialState: IWSysCoreState = {
	layout: {
		showPreferences: false,
		showDebug: false,

		dumpPath: false,
		dumpLayout: false,
		dumpLayoutCalc: false,
		dumpFormTokens: false,
		/*dumpClick: false,
		dumpPanels: false,
		dumpPanelsScroll: false,
		dumpAutocomplete: false,
		dumpTable: false,
		dumpForm: false,*/
		... savedState
	},
	preferences: {
		darkMode : window.localStorage.getItem('chakra-ui-color-mode') === 'dark',
		baseColor: savedPreferences.baseColor || '#2c6195',
		selectionColor: savedPreferences.selectionColor || '#ffffcc',
		/*
		secondaryColor: savedPreferences.baseColor || '#2c9561',
		mutationColor: savedPreferences.baseColor || '#C05621',

		contrast: savedPreferences.contrast || 'Kontraszt',
		*/
		modeMain: savedPreferences.modeMain || 'POPUPS',
		modeAttach: savedPreferences.modeAttach || 'COLUMNS',
		modePopup: savedPreferences.modePopup || 'POPUPS',
		layoutAlign: savedPreferences.layoutAlign || 'CENTER',

		colors: {
			theme1: savedPreferences.colors?.theme1 || { value:'#2c6195' },
			theme2: savedPreferences.colors?.theme1 || { value:'#2c6195' },
			menu: savedPreferences.colors?.menu || { value:'#2c6195' },
			tableHead: savedPreferences.colors?.tableHead || { value:'#2c6195' },
		}
	},
	unsavedforms: []
}


export const coreSlice = createSlice({
	name: 'core',
	initialState,
	reducers: {
		showPreferencesSet: (state, action: PayloadAction<boolean>) => {
			state.layout.showPreferences = action.payload;
		},
		showDebugSet: (state, action: PayloadAction<boolean>) => {
			state.layout.showDebug = action.payload;
		},
		// ------------ preferences --------------
		preferencesSet: (state, action: PayloadAction<IWSysCorePreferences>) => {

			state.preferences = action.payload;
			//nagyon fújos:
			window.localStorage.setItem('PREFERENCES', JSON.stringify(action.payload));
			window.localStorage.setItem('PREFERENCES___', JSON.stringify(new Date().toTimeString()));
		},
		colorSet: (state, action: PayloadAction<{colorKey: TWSysColor, colorValue: WSysColorValue}>) => {
			state.preferences.colors[action.payload.colorKey] = action.payload.colorValue;
			//nagyon fújos:
			window.localStorage.setItem('PREFERENCES', JSON.stringify(state.preferences));
		},
		coreStateSet: (state, action: PayloadAction<Partial<IWSysCoreLayoutState>>) => {
			state.layout = {... state.layout, ...action.payload};
			//nagyon fújos:
			window.localStorage.setItem('UX_STATE', JSON.stringify(state.layout));
		},
		// ------------ unsavedforms --------------
		unsavedAdd: (state, action: PayloadAction<string>) => {
			state.unsavedforms.push({ url: action.payload });
		},
		unsavedRemove: (state, action: PayloadAction<string>) => {
			state.unsavedforms = [];
		},
	}
})

// Action creators are generated for each case reducer function
/*export const {
	//TODO signedIn, 
	setX
} = coreSlice.actions*/

export const coreSliceReducer = coreSlice.reducer



