import { useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react';
import { BrowserProvider, Contract, JsonRpcSigner, formatUnits } from 'ethers';
import { useEffect, useState } from 'react';
import { usdt_address, sale_address, token_address, web3modal, payout_address } from './config';
import { usdt_abi } from './usdt.abi';
import { useSunStore } from '../store/sunSlice';
import { sale_abi, token_abi } from './sale.abi';
import { payout_abi } from './payout.abi';


const xweb3modal = web3modal;

export function useWeb3() {
	const { address, chainId, isConnected } = useWeb3ModalAccount();

	const connectedWallet = useSunStore(state => state.connectedWallet);
	const initialRequest = useSunStore(state => state.initialRequest);
	const setWalletDisconnected = useSunStore(state => state.walletDisconnected);
	const setWalletConnected = useSunStore(state => state.walletConnected);

	const loadBalance = useSunStore(state => state.loadBalance);
	const loadOwnerInfo = useSunStore(state => state.loadOwnerInfo);


	useEffect(() => {
		const reallyConnected = isConnected && !!address && chainId && walletProvider;
		console.log('ehun::::', address, chainId, isConnected, connectedWallet);

		const walletEqualsStored = () => {
			return !!connectedWallet
				&& (connectedWallet.address === address)
				&& (connectedWallet.chainId === chainId)
				;
		}
		if (!reallyConnected && connectedWallet) {
			setWalletDisconnected();
		} else if (reallyConnected && !walletEqualsStored()) {
			(async () => {
				const ethersProvider = new BrowserProvider(walletProvider);
				const signer = await ethersProvider.getSigner();

				const TokenContract = new Contract(token_address, token_abi, signer);
				const USDTContract = new Contract(usdt_address, usdt_abi, signer);
				const SaleContract = new Contract(sale_address, sale_abi, signer);
				const PayoutContract = new Contract(payout_address, payout_abi, signer);

				setWalletConnected({
					walletProvider,
					chainId,
					address,
					ethersProvider,
					TokenContract, USDTContract, SaleContract, PayoutContract,
					signer,
				})
			})();
		}
	}, [address, chainId, isConnected, connectedWallet])

	const { walletProvider } = useWeb3ModalProvider();

	useEffect(() => {
		if (connectedWallet && !initialRequest) {
			(async () => {
				await loadBalance();
				await loadOwnerInfo();
			})();
			
		}

	}, [connectedWallet, initialRequest])


	// -------------------------------------------------------------------
	// -------------------------------------------------------------------

}