import { BigNumberish, BrowserProvider, Contract, Eip1193Provider, JsonRpcSigner, formatUnits } from 'ethers';
import { create } from 'zustand'
//import { devtools, persist } from 'zustand/middleware'
import { payout_address, sale_address, usdt_address } from '../web3/config';
import { usdt_abi } from '../web3/usdt.abi';
import { WSysUseResult } from '@coimbra-its/websys-ux-core';
//import type { } from '@redux-devtools/extension' // required for devtools typing

export interface ISunConnectedWallet {
    walletProvider: Eip1193Provider,
    address: `0x${string}`,
    chainId: number,
    ethersProvider: BrowserProvider,
    signer: JsonRpcSigner,
    TokenContract: Contract,
    USDTContract: Contract,
    SaleContract: Contract,
    PayoutContract: Contract,
}

export interface ISunWalletAmounts {
    TokenBalance: BigNumberish;
    USDTBalance: BigNumberish;
    USDTApprovedLimit: BigNumberish;
    BNBBalance: BigNumberish;

    lvl1Bonus: BigNumberish;
    lvl2Bonus: BigNumberish;
    lvl3Bonus: BigNumberish;

    walletRecoveryId: string;
    myReferralName: string;
    parentReferralSavedAddress: string;
    parentReferralSavedName: string;
}

export interface ISunOwnerInfo {
    allSold: BigNumberish;
    lvl1SumBonus: BigNumberish;
    lvl2SumBonus: BigNumberish;
    lvl3SumBonus: BigNumberish;
    allSumBonus: BigNumberish;
}

export interface ISunPayout {
    id: string;
    status : string; 
    userAmount : BigNumberish;
    date : number;
}

export interface ISunPayoutInfo {
    payouts : ISunPayout[];
    userSum : BigNumberish; 
    userWithdrawn : BigNumberish;
    withdrawAble : BigNumberish;
}



export interface SunState {
    isLoading: boolean,
    walletError: string,
    setIsLoading: (v: boolean) => void;
    setWalletError: (v: string) => void;


    // --------------------------------
    connectedWallet: ISunConnectedWallet | null,
    walletDisconnected: () => void,
    walletConnected: (sunWalletProps: ISunConnectedWallet) => void,

    initialRequest: boolean;
    setInitialRequest: (v: boolean) => void;

    walletAmounts: ISunWalletAmounts;
    setWalletAmounts: (v: Partial<ISunWalletAmounts>) => void;
    ownerInfo: ISunOwnerInfo;
    setOwnerInfo: (v: Partial<ISunOwnerInfo>) => void;
    payoutInfo: ISunPayoutInfo;


    // --------------
    loadBalance: () => void;
    loadOwnerInfo: () => void;
    loadPayouts: () => void;
    loadPayoutsRemote: WSysUseResult<any>;
    setLoadPayoutsRemote: (v : Partial<WSysUseResult<any>>) => void;
    

}

export const useSunStore = create<SunState>()(
    (set, get) => ({
        isLoading: false,
        walletError: '',
        setIsLoading: (v) => set(state => ({ isLoading: v })),
        setWalletError: (v) => set(state => ({ walletError: v })),
        initialRequest: false,
        setInitialRequest: (v) => set(state => ({ initialRequest: v })),

        // ----------------------------------

        connectedWallet: null,
        walletDisconnected: () => set(state => ({
            connectedWallet: null,
            initialRequest: false,
        })),
        walletConnected: (sunWalletProps) => set(state => ({
            connectedWallet: sunWalletProps,
            initialRequest: false,
        })),

        // ------

        walletAmounts: {
            TokenBalance: BigInt(0),
            USDTBalance: BigInt(0),
            USDTApprovedLimit: BigInt(0),
            BNBBalance: BigInt(0),

            lvl1Bonus: BigInt(0),
            lvl2Bonus: BigInt(0),
            lvl3Bonus: BigInt(0),

            walletRecoveryId: '?',
            myReferralName: '?',
            parentReferralSavedAddress: '?',
            parentReferralSavedName: '?',
        },
        setWalletAmounts: (v) => set(state => ({
            walletAmounts: Object.assign({}, get().walletAmounts, v)
        })),

        ownerInfo: {
            allSold: BigInt(0),
            lvl1SumBonus: BigInt(0),
            lvl2SumBonus: BigInt(0),
            lvl3SumBonus: BigInt(0),
            allSumBonus: BigInt(0),
        },
        setOwnerInfo: (v) => set(state => ({
            ownerInfo: Object.assign({}, get().ownerInfo, v)
        })),        

        payoutInfo: {
            payouts : new Array<ISunPayout>(),
            userSum : BigInt(0), 
            userWithdrawn : BigInt(0),
            withdrawAble : BigInt(0),
        },
        

        // ==========================================================
        // -------------------- ACTIONS -----------------------------
        // ==========================================================
        // --------------------
        loadBalance: async () => {
            try {
                get().setIsLoading(true);
                get().setWalletError('');
                const wallet = get().connectedWallet;
                if (!wallet)
                    throw Error('User disconnected');
                console.log('---------------------------------------');
                console.log(wallet.address, usdt_address, !!usdt_abi);


                const TokenBalance = await wallet.TokenContract.balanceOf(wallet.address);
                const USDTBalance = await wallet.USDTContract.balanceOf(wallet.address);
                const USDTApprovedLimit = await wallet.USDTContract.allowance(wallet.address, payout_address);
                const BNBBalance = await wallet.ethersProvider.getBalance(wallet.address);

                const lvl1Bonus = await wallet.SaleContract.referralLvl1Bonus(wallet.address);
                const lvl2Bonus = await wallet.SaleContract.referralLvl2Bonus(wallet.address);
                const lvl3Bonus = await wallet.SaleContract.referralLvl3Bonus(wallet.address);

                const walletRecoveryId = await wallet.SaleContract.walletConfirm(wallet.address);

                const myReferralName = await wallet.SaleContract.referralAddressName(wallet.address);
                const parentReferralSavedAddress = await wallet.SaleContract.referralTree(wallet.address);
                const parentReferralSavedName = await wallet.SaleContract.referralAddressName(wallet.address);

                console.log('Tokenbalance:', TokenBalance, typeof TokenBalance);
                set((state) => ({
                    walletAmounts: {
                        TokenBalance,
                        USDTBalance,
                        USDTApprovedLimit,
                        BNBBalance,

                        lvl1Bonus,
                        lvl2Bonus,
                        lvl3Bonus,

                        walletRecoveryId,
                        myReferralName,
                        parentReferralSavedAddress,
                        parentReferralSavedName,
                    }
                }))
            } catch (ex) {
                get().setWalletError('' + ex);
            } finally {
                get().setIsLoading(false);
            }
        },


        // --------------------
        loadOwnerInfo: async () => {
            try {
                get().setIsLoading(true);
                get().setWalletError('');
                const wallet = get().connectedWallet;
                if (!wallet)
                    throw Error('User disconnected');

                const allSold = await wallet.SaleContract.soldAmount();
                const lvl1SumBonus = await wallet.SaleContract.bonusLvl1();
                const lvl2SumBonus = await wallet.SaleContract.bonusLvl2();
                const lvl3SumBonus = await wallet.SaleContract.bonusLvl3();
                const allSumBonus = lvl1SumBonus + lvl2SumBonus + lvl3SumBonus;
                set((state) => ({
                    ownerInfo: {
                        allSold, 
                        lvl1SumBonus,
                        lvl2SumBonus,
                        lvl3SumBonus,
                        allSumBonus
                    }
                }))
            } catch (ex) {
                get().setWalletError('' + ex);
            } finally {
                get().setIsLoading(false);
            }
        },

        // --------------------
        loadPayoutsRemote: {
            isLoading: false,
            isError: false,
            error: '',
            reset: () => {
                set(status => ({
                    loadPayoutsRemote: Object.assign({}, status.loadPayoutsRemote, {
                        isLoading: false,
                        isError: false,
                        error: '',            
                    })
                }))
            },
        },
        setLoadPayoutsRemote: (v : Partial<WSysUseResult<any>>) => {
            set(status => ({
                loadPayoutsRemote: Object.assign({}, status.loadPayoutsRemote, v)
            }))
        },

        loadPayouts: async () => {
            console.log('----load payout----')
            try {
                get().setLoadPayoutsRemote({isLoading: false, error: ''})
                const wallet = get().connectedWallet;
                if (!wallet)
                    throw Error('User disconnected');
                let payouts = new Array<ISunPayout>();

        


                let wDates = await wallet.PayoutContract.getDates();
                console.log('dates', wDates);
        
                let wAmounts = await wallet.PayoutContract.getUserData(wallet.address);
                console.log('amounts', wAmounts);
            
                let userSum = await wallet.PayoutContract.userSum(wallet.address);
                console.log('userSum', userSum);
            
                let userWithdrawn = await wallet.PayoutContract.userWithdrawn(wallet.address);
                console.log('userWithdrawn', userWithdrawn);
            
                let withdrawAble = userSum - userWithdrawn;
                console.log('withdrawAble', withdrawAble);
        
        
                //let payouts = [];
                for (let id in wDates) {
                    let date = wDates[id];
                    let amount = wAmounts[id];
                    payouts.push({
                        id, userAmount: amount, date, status:''
                    });
                }

                /*for (let i = 0; i<50; i++) {
                    payouts.push({
                        id: ''+i, date : 202304, status: 'ok', userAmount: BigInt(150 * 1e18)
                    });
                }*/


                console.log('payouts:', payouts);
                set((state) => ({
                    payoutInfo : {
                        payouts, userSum, userWithdrawn, withdrawAble
                    }
                }))
            } catch (ex) {
                get().setLoadPayoutsRemote({error: ''+ex as any, isError: true});
            } finally {
                get().setLoadPayoutsRemote({isLoading: false});
            }
        },


    }),
)