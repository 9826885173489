import { Box, Button, Checkbox, ColorMode, FormControl, FormLabel, Grid, GridItem, HStack, Select, Slider, SliderThumb, SliderTrack, Stack, Switch, VStack, useColorMode, useDisclosure } from "@chakra-ui/react";
import { WSysLayoutPage, useWSysPage } from "./LayoutManager";
import { useWSysPath } from "./Path";
import { useDispatch, useSelector } from "react-redux";
import { IWSysCorePreferences, TWSysColor, TWSysColorDeriveMethod, TWSysColorDeriveMethods, TWSysLayoutPageMode, WSysColorValue, coreSlice } from "../store/coreSlice";
import { IRootStateCore, useWSysPreferences } from "./utils";
import { WSysPage, WSysPageInner, WSysVert } from "./Page";
import { useEffect, useState } from "react";
import chroma from "chroma-js";


const COLORS_BASIC = ['#2c6195', '#234a70', '#1f6878', '#3c7859', '#006d67', '#795072', '#aa5060', '#555555', '#df8c24'];

export function WSysPreferences() {
	const dispatch = useDispatch();
	const layoutState = useSelector((root: IRootStateCore) => root.core.layout)
	const preferences = useWSysPreferences();

	// --- path ---
	const path = useWSysPath();
	const child = path.useChild('child');

	// --- page ---
	const page = useWSysPage({ maxCols: 3, title: 'Preferences', variant: 'popup' });
	const preferencesSet = coreSlice.actions.preferencesSet;

	const setPref = (name: keyof IWSysCorePreferences, value: any) => {
		dispatch(preferencesSet({ ...preferences, ...{ [name]: value } }));
	}

	const { colorMode, setColorMode } = useColorMode()
	const mySetColorMode = (colorMode: ColorMode) => {
		setColorMode(colorMode);
		setPref('darkMode', colorMode === 'dark');
	}


	return <WSysLayoutPage page={page}><WSysPageInner>


		<VStack>
			<FormControl>
				<FormLabel>Dark mode</FormLabel>
				<Switch isChecked={colorMode === 'dark'} onChange={(e) => mySetColorMode(e.currentTarget.checked ? 'dark' : 'light')} />

			</FormControl>

			<HStack>
				<VStack>
					<FormLabel>Oldalelrendezés</FormLabel>
					<Select value={preferences.modeMain}
						onChange={(e) => dispatch(preferencesSet({ ...preferences, modeMain: e.currentTarget.value as TWSysLayoutPageMode }))}  >
						<option value='LASTONLY' >LASTONLY</option>
						<option value='COLUMNS' >COLUMNS</option>
						<option value='POPUPS' >POPUPS</option>
					</Select>
				</VStack>
				<VStack>
					<FormLabel>Popup </FormLabel>
					<Select value={preferences.modePopup}
						onChange={(e) => dispatch(preferencesSet({ ...preferences, modePopup: e.currentTarget.value as TWSysLayoutPageMode }))}  >
						<option value='COLUMNS' >COLUMNS</option>
						<option value='LASTONLY' >LASTONLY</option>
						<option value='POPUPS' >POPUPS</option>
					</Select>
				</VStack>
				<VStack>
					<FormLabel>Attach </FormLabel>
					<Select value={preferences.modeAttach}
						onChange={(e) => dispatch(preferencesSet({ ...preferences, modeAttach: e.currentTarget.value as TWSysLayoutPageMode }))}  >
						<option value='COLUMNS' >COLUMNS</option>
						<option value='LASTONLY' >LASTONLY</option>
						<option value='POPUPS' >POPUPS</option>
					</Select>
				</VStack>
				<VStack>
					<FormLabel>Align</FormLabel>
					<Select value={preferences.layoutAlign}
						onChange={(e) => dispatch(preferencesSet({ ...preferences, layoutAlign: e.currentTarget.value as 'LEFT' | 'CENTER' }))}  >
						<option value='LEFT' >LEFT</option>
						<option value='CENTER' >CENTER</option>
					</Select>
				</VStack>
			</HStack>
		</VStack>


		<Box p={2}>
			<WSysColorPicker value={preferences.baseColor} onSelect={(c) => dispatch(coreSlice.actions.preferencesSet({ ...preferences, baseColor: c }))} />
		</Box>
		<HStack p={2}>
			<WSysColorPicker2 colorKey='theme1' baseColors={[]} />
			<WSysColorPicker2 colorKey='theme2' baseColors={['theme1']} />
			<WSysColorPicker2 colorKey='menu' baseColors={['theme1', 'theme2']} />
			<WSysColorPicker2 colorKey='tableHead' baseColors={['theme1', 'theme2']} />
		</HStack>
		<hr />
		<HStack p={2} >
			<Checkbox
				isChecked={layoutState.dumpPath}
				onChange={(e) => dispatch(coreSlice.actions.coreStateSet({ dumpPath: e.currentTarget.checked }))}
			>Dump path</Checkbox>
			<Checkbox
				isChecked={layoutState.dumpLayout}
				onChange={(e) => dispatch(coreSlice.actions.coreStateSet({ dumpLayout: e.currentTarget.checked }))}
			>Dump layout</Checkbox>
			<Checkbox
				isChecked={layoutState.dumpLayoutCalc}
				onChange={(e) => dispatch(coreSlice.actions.coreStateSet({ dumpLayoutCalc: e.currentTarget.checked }))}
			>Dump layout calc</Checkbox>
			<Checkbox
				isChecked={layoutState.dumpFormTokens}
				onChange={(e) => dispatch(coreSlice.actions.coreStateSet({ dumpFormTokens: e.currentTarget.checked }))}
			>Dump form tokens</Checkbox>
		</HStack>
	</WSysPageInner></WSysLayoutPage >
}

// =============================================== COLOR PICKERS =================================
interface SelectColorProps {
	value: string;
	onSelect: (color: string) => void;
}
function WSysColorPicker({ onSelect, value }: SelectColorProps) {

	const showPage = useDisclosure();
	const onPageSelect = (c: string) => {
		if (c)
			onSelect(c);
		showPage.onClose();
	}


	return <>
		<Box w='30px' h='30px' bg={value} onClick={showPage.onToggle} cursor='pointer' ></Box>
		{showPage.isOpen && <WSysColorPickerPage value={value} onSelect={onPageSelect} />}
	</>
}

function WSysColorPickerPage({ onSelect, value }: SelectColorProps) {
	const page = useWSysPage({ title: 'Color picker', maxCols: 1, onClosed: () => onSelect(''), variant: 'popup' })
	const preferences = useWSysPreferences();


	return <WSysPage page={page}>
		<WSysVert mode="static">
			<Grid p={2} gap={2} templateColumns='repeat(3, 1fr)'>
				{COLORS_BASIC.map(c => <GridItem key={c} w='30px' h='30px' bg={c}
					{...c === value && { outline: '2px solid #444' }}
					onClick={() => onSelect(c)} cursor='pointer' ></GridItem>)}
			</Grid>
			<Stack p={2}>
				<Button variant='solid' onClick={() => onSelect('=baseColor')} >Ugyanaz</Button>
			</Stack>
		</WSysVert>
	</WSysPage>
}

// =============================================== COLOR PICKERS =================================
interface SelectColorProps2 {
	colorKey: TWSysColor
	baseColors: TWSysColor[];
	onClosed?: () => void;
}

function WSysColorPicker2({ colorKey, baseColors }: SelectColorProps2) {
	const preferences = useWSysPreferences();
	const color = preferences.colors[colorKey];
	const showPage = useDisclosure();
	const onPageSelect = (c: string) => {
		showPage.onClose();
	}


	return <>
		<Box h='30px' bg={color.value} onClick={showPage.onToggle} cursor='pointer' fontSize='sm' color='#fff' textShadow='1px 1px 1px #000' >{colorKey}</Box>
		{showPage.isOpen && <WSysColorPickerPage2 colorKey={colorKey} baseColors={baseColors} onClosed={() => showPage.onClose()} />}
	</>
}




function WSysColorPickerPage2({ colorKey, baseColors, onClosed }: SelectColorProps2) {

	const page = useWSysPage({ title: colorKey, maxCols: 1, variant: 'popup', onClosed })

	const preferences = useWSysPreferences();
	const dispatch = useDispatch();
	const [colorValue, setColorValue] = useState(preferences.colors[colorKey]);
	const defColor =  COLORS_BASIC[0];


	// ------------------------ METHOD ------------------------
	const methodSelect = (m: TWSysColorDeriveMethod) => {
		if (m === 'DIRECT') {
			setColorValue({ value: colorValue.value || defColor });
		}
		if (m === 'SHADE') {
			setColorValue({ value: preferences.colors[baseColors[0]].value, derived: { method: 'SHADE', color1: baseColors[0] } });
		}
	}

	// ------------------------ direct ------------------------	
	const onDirectSelect = (c: string) => {
		setColorValue({ value: c });
	}

	// ------------------------ shade ------------------------	
	const onBase1Select = (ck: TWSysColor) => {
		const newVal = { ...colorValue };
		if (!newVal.derived)
			return;
		newVal.derived.color1 = ck;
		setColorValue(newVal);
	}

	const onP1Select = (n : number) => {
		let newVal = JSON.parse(JSON.stringify(colorValue)) as WSysColorValue;
		if (!newVal.derived)
			return;
		newVal.derived.p1 = n;
		setColorValue(newVal);
	}



	// ------------------------
	useEffect(() => {
		let c = colorValue.value;		
		switch (colorValue.derived?.method) {
			case 'SHADE' :
				let base1 = (colorValue.derived?.color1 || baseColors[0]) as TWSysColor;
				let base1col = preferences.colors[base1]?.value || defColor;
				let p1 = colorValue.derived?.p1 || 50;
				let baseColorHsl = chroma(base1col).hsl();
				c = chroma(baseColorHsl[0], baseColorHsl[1], 1 - (p1 / 100), 'hsl').hex();
			break;
			
		}

		if (colorValue.value !== c) {
			const newVal = { ...colorValue  };
			newVal.value = c;
			setColorValue(newVal);
		} else {
			dispatch(coreSlice.actions.colorSet({ colorKey, colorValue: colorValue }));
		}
	}, [colorValue])

	const method = colorValue.derived?.method || 'DIRECT';

	return <WSysPage page={page}>
		<WSysVert mode="static">
			<Box p={2}>
				<Select value={method} onChange={e => methodSelect(e.currentTarget.value as TWSysColorDeriveMethod)} >
					{TWSysColorDeriveMethods.filter(m => baseColors.length > 0 || m === 'DIRECT').map(m => <option key={m} value={m}>{m}</option>)}
				</Select>

			</Box>

			{method === 'DIRECT' && <Grid p={2} gap={2} templateColumns='repeat(3, 1fr)'>
				{COLORS_BASIC.map(c => <GridItem key={c} w='30px' h='30px' bg={c}
					{...c === colorValue.value && { outline: '2px solid #444' }}
					onClick={() => onDirectSelect(c)}
					cursor='pointer' ></GridItem>)}
			</Grid>}

			<Stack p={2}>
				{method !== 'DIRECT' && <Select value={colorValue.derived?.color1} onChange={e => onBase1Select(e.currentTarget.value as TWSysColor)}  >
					{baseColors.map(bck => <option key={bck} value={bck}>{bck}</option>)}
				</Select>}
			</Stack>

			<hr />

			<Slider defaultValue={50} min={0} max={100} step={10} onChange={val => onP1Select(val)}>
				<SliderTrack>
					{/*<SliderFilledTrack bg='tomato' />*/}
				</SliderTrack>
				<SliderThumb />
			</Slider>

			<hr />
			<Box bg={colorValue.value} h='30px' >

			</Box>
		</WSysVert>
	</WSysPage>
}

