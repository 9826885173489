import { Box, BoxProps, Button, ButtonProps, Heading, IconButton, useToken } from "@chakra-ui/react";
import { Children as ReactChildren, ReactNode, cloneElement, isValidElement, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { HEADER_HEIGHT_PX } from "./Layout.TODO";
import { IWSysLayoutContext, WSysLayoutContext, WSysLayoutPage, WSysPageNode, useWSysLayoutContext } from "./LayoutManager";
import { WSysRemote, WSysRemoteProps } from "./Remote";
import { ICONS } from "./theme";
import { WSysScroll } from "./utils";


export interface WSysHeaderButtonProps extends Omit<ButtonProps, 'leftIcon'> {
    icon: React.ReactElement<any>
    minCols? : number;
}

export function WSysHeaderButton({ minCols = 1, icon, ...rest }: WSysHeaderButtonProps) {
    const layoutCtx = useContext(WSysLayoutContext);
    return !layoutCtx.isMobileFull && ((layoutCtx.page?.propsOut.widthCols||minCols)>=1)
        ? <Button size='sm' leftIcon={icon}  {...rest} />
        : <IconButton px={4} size='sm' icon={icon} {...rest} aria-label={typeof rest.children === 'string' ? rest.children : ''} />
}


export interface WSysHeaderProps extends BoxProps {
	headerTitle? : ReactNode;
	tools? : ReactNode;
}

export function WSysHeader({ headerTitle, tools, ...rest }: WSysHeaderProps) {

	const {page, isMobileFull} : IWSysLayoutContext = useWSysLayoutContext();
    const navigate = useNavigate();

	if (!headerTitle) {
		headerTitle = <Heading size='md'  textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden' >{page.propsIn.title}</Heading>;
	}

	return <Box 
		className='wsys-page-head'
		h={`${HEADER_HEIGHT_PX}px`} flex='0 0 auto'
		display='flex' flexDir='row' justifyContent='space-between' alignItems='center'
		
		borderBottom={`${page.propsOut.layerKind==='popup'?2:4}px solid ${useToken('colors', 'wsysLine')}`}
		{...rest}
	>
		<Box>
			{page.onClosed && <IconButton aria-label='vissza' onClick={() => page.close()} isDisabled={page.propsIn.isEdited} size='md' variant='wsysHeadIconButton' icon={<ICONS.Back />}  />}
            {(!page.onClosed && isMobileFull && !page.propsIn.isEdited) && <IconButton aria-label="home" onClick={() => navigate('/')} size='md' variant='wsysHeadIconButton' icon={<ICONS.Home />} />}

		</Box>
		<Box flex={1} display='flex' flexDir='row' alignItems='center' justifyContent='flex-start'
			overflow='hidden'
            px={2}
		>
			{headerTitle}
		</Box>
		{tools && <Box px={2}>
			{tools}
		</Box>}
	</Box>
}

// ==================================================== PAGE INNER ==================================================
export interface WSysPageInnerProps extends  Omit<BoxProps, 'order' | 'title'> {
    header?: ReactNode;
    headerTitle?: ReactNode;
    tools?: ReactNode;

}

export function WSysPageInner({header, headerTitle, tools, children, ...rest} : WSysPageInnerProps) {
    if (!header) {
        header = <WSysHeader headerTitle={headerTitle} tools={tools} />;
    }

    return <Box className='wsys-page' display='flex' flexDir='column' w='100%' h='100%' overflow='hidden'
        bg='var(--chakra-colors-chakra-body-bg)'
        {...rest}
    >
        {header}
        <Box className='wsys-page-body'
            flex={1}
            
        >
            {children}
        </Box>
    </Box>
}




// ==================================================== PAGE 0 ==================================================
export interface WSysPage0Props extends  WSysPageInnerProps, WSysRemoteProps {
    page : WSysPageNode;
}

export function WSysPage0({page, children, remotes, ...rest}: WSysPage0Props) {


    return <WSysLayoutPage page={page}>
        <WSysPageInner {...rest} >
            <WSysRemote remotes={remotes} >
                {children}
            </WSysRemote>
        </WSysPageInner>
    </WSysLayoutPage>
}

// ==================================================== PAGE ==================================================
export interface WSysPageProps extends WSysPage0Props {
    vertMode? : WSysVertGroupMode;
    fullScroll? : number;
}

export function WSysPage({ page, vertMode, children, fullScroll= 1, ...rest} : WSysPageProps) {
    
    
    if (!vertMode && page)
        vertMode  = page.propsOut.widthCols>fullScroll?'grow':'scroll'

    return <WSysPage0 {...rest} page={page} >
        <WSysVertGroup mode={vertMode} >
            {children}
        </WSysVertGroup>
    </WSysPage0>
}

//  ============================================================ VERTICAL ==================================================
//  ============================================================ VERTICAL ==================================================
//  ============================================================ VERTICAL ==================================================

export type WSysVertGroupMode = 'fix' | 'grow' | 'scroll';

export interface WSysVertGroupProps extends BoxProps {
    mode?: WSysVertGroupMode;
}


export function WSysVertGroup({ children, mode = 'grow', ...rest }: WSysVertGroupProps) {

    const clonedChildren = <>
        {ReactChildren.map(children, child => (
            isValidElement(child)
                ? cloneElement(child as any, { __groupMode: mode })
                : child
        ))}
    </>

    return <>

        {mode === 'fix' &&
            <Box className='wsys-vert-group-fix'
                flex={1} display='flex' flexDir='column' justifyContent='stretch' w='100%' h='100%' overflow='hidden'
                {...rest}
            >
                {clonedChildren}
            </Box>}


        {mode === 'grow' &&
            <Box className='wsys-vert-group-grow'
                flex={1} display='flex' flexDir='column' w='100%' h='100%' overflow='hidden'
                {...rest}
            >
                {clonedChildren}
            </Box>}

        {mode === 'scroll' &&
            <WSysScroll>
                <Box className='wsys-vert-group-scroll'
                    minHeight='100%'
                    display='flex' flexDir='column'
                    {...rest}
                >
                    {clonedChildren}
                </Box>
            </WSysScroll>
        }
    </>
}

export interface WSysVertStaticProps extends BoxProps {
    __groupMode?: WSysVertGroupMode;
    mode: 'static' | 'inline' | 'rest';
    grow?: number;
}

export function WSysVert({ __groupMode, mode, grow = 1, children, ...rest }: WSysVertStaticProps) {
    if (!__groupMode)
        throw `Missing <WSysVertGroup> around <WSysVert>`;

    return <>
        {!__groupMode && <Box bg='red'>Missing group mode!</Box>}

        {/* ========================  group: FIX  ======================== */}
        {__groupMode === 'fix' &&
            <Box className='wsys-vert wsys-vert-fix'
                flex={`0 1 ${grow * 100}%`}
                {...rest}
                h={(200 * grow)}
            >
                <WSysScroll>
                    {children}
                </WSysScroll>
            </Box>
        }
        {/* ========================  group: GROW  ======================== */}

        {mode === 'static' && __groupMode === 'grow' &&
            <Box className='wsys-vert-static wsys-vert-mode-grow'
                flex='1 1 auto' {...rest}
            >
                {children}
            </Box>
        }
        {mode != 'static' && __groupMode === 'grow' &&
            <Box className='wsys-vert-table wsys-vert-mode-grow'
                flex='1 1 100%' {...rest} >
                <WSysScroll>
                    {children}
                </WSysScroll>
            </Box>
        }


        {/* ========================  group: SCROLL  ======================== */}
        {mode === 'static' && __groupMode === 'scroll' &&
            <Box {...rest}>
                {children}
            </Box>
        }
        {mode != 'static' && __groupMode === 'scroll' &&

            children

        }

    </>

}