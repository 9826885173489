import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'

//import { SiweMessage } from 'siwe'
import { IStartupPostApi } from '@coimbra-its/api.sdbn1'
import type { SIWECreateMessageArgs, SIWESession, SIWEVerifyMessageArgs } from '@web3modal/siwe'
import { createSIWEConfig, formatMessage } from '@web3modal/siwe'
import { API_BASE } from '../components/WSysApi'

// 1. Get projectId
//export const projectId = '7a277e3a4080047662df69371aeaacca'
export const projectId = '63242127221a9365f9f87e48d552536d'

// 2. Set chains
/* TEST:
const mainnet = {
	chainId: 0x61,
	name: 'Binance Smart Chain Testnet',
	currency: 'tBNB',
	explorerUrl: 'https://testnet.bscscan.com',
	rpcUrl: 'https://data-seed-prebsc-1-s3.binance.org:8545'
}*/

export const mainnet = {
	chainId: 0x38,
	name: 'Binance Smart Chain',
	currency: 'BNB',
	explorerUrl: 'https://bscscan.com/',
	rpcUrl: 'https://bsc-dataseed1.binance.org/',
}

// 3. Create a metadata object
export const metadata = {
	name: 'sunmoney-walletconect-demo',
	description: 'Sunmoney Walletconnect demo',
	//url: 'https://walletconnect-demo.sunmoney.net', // origin must match your domain & subdomain
	//icons: ['https://walletconnect-demo.sunmoney.net/icons']
	url: 'https://localhost:3000', // origin must match your domain & subdomain
	icons: ['https://https://localhost:3000/icons']
}

// 4. Create Ethers config
export const ethersConfig = defaultConfig({
	/*Required*/
	metadata,
	enableEmail: true,
	/*Optional*/
	enableEIP6963: true, // true by default
	enableInjected: true, // true by default
	enableCoinbase: true, // true by default
	rpcUrl: mainnet.rpcUrl, //??   '...', // used for the Coinbase SDK
	defaultChainId: mainnet.chainId,  //??  1, // used for the Coinbase SDK
})




// ==========================================================================================================
// ---------------------------------------------- SIWE ------------------------------------------------------
// ==========================================================================================================

// (sign in with ethers) https://docs.walletconnect.com/web3modal/react/siwe

async function getMessageParams() {
	return {
		domain: window.location.host,
		uri: window.location.origin,
		chains: [mainnet.chainId],
		statement: 'Please sign with your account'
	}
}

const siweConfig = createSIWEConfig({
	getMessageParams,
	createMessage: ({ address, ...args }: SIWECreateMessageArgs) => { 
		console.log('-------*** createMessage ***', address, args);
		const msg = formatMessage(args, address);
		console.log('-------  formatMessage ----------', msg);
		return msg; //'Sign token with your wallet to login to Sunmoney';
	},
	getNonce : async (address? : string) => { 
		console.log('-------*** getNonce ***', address);
		if (!address)
			throw new Error('No address');

		//const resp = await api.fetch<IStartupPostApi>({ path: '/startup', method: 'POST' });
		const resp = await fetch(API_BASE + '/startup', {
			method:'POST',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				walletAddress : address
			})
		});
		const res = (await resp.json()) as IStartupPostApi['response']['payload'];
		if (!res.nonce) 
			throw new Error('No nonce!');
		window.localStorage.setItem('sdbn1_wallet', address);
		window.localStorage.setItem('sdbn1_nonce', res.nonce);
		return res.nonce;
	},
	getSession : async () => {
		console.log('-------*** getSession ***');
		const sess : SIWESession = {
			address: '0x123-- TODO',
			chainId: mainnet.chainId
		}
		return null;
	},
	verifyMessage : async (args : SIWEVerifyMessageArgs) => { 
		console.log('-------*** verifyMessage ***', args);

		const sess_address = window.localStorage.getItem('sdbn1_wallet');
		const sess_nonce = window.localStorage.getItem('sdbn1_nonce');

		const resp = await fetch(API_BASE + '/login', {
			method:'POST',
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				walletAddress : sess_address,
				nonce : sess_nonce,
				nonceSigned : args.signature,
				message: args.message,
			})
		});

		const res = (await resp.json()) as IStartupPostApi['response']['payload'];		
		return true 
	},
	signOut : async () => { 
		console.log('-------*** signOut ***');
		return true 
	},
})

// 5. Create a Web3Modal instance
export const web3modal = createWeb3Modal({
	ethersConfig,
	chains: [mainnet],
	projectId,
	enableAnalytics: true, // Optional - defaults to your Cloud configuration
	//siweConfig,
})


//const USDTAddress = '0x617f3112bf5397D0467D315cC709EF968D9ba546'
export const usdt_address = "0x55d398326f99059fF775485246999027B3197955";  //ok
//sdbn2plus hoz
//const payout_address = "0x62F915d3bCE0119862d3d68C1649341b930c1719";
//sdbn1 hez
//export const payout_address = "0xE7D161a581c0Ee4a351d13d3b7Ac26E5Ac346Cf2";
//export const payout_address = "0x956238D6835e4Dc9e038f01cE02CaE70e8998a6c";
export const payout_address = "0xa737F3B9f5E73344770B76B0F39c63517968966C";

// régiből:
export const sale_address = "0x296682C515EAaB48F13F38C74797B0F40407B46e"; //... buy
export const token_address = "0xC605eac37a26927F38284387048AaF1395B1D9e3"; //ok
export const PAYOUT_IGNORE_ACCOUNTS = [sale_address];

// ------------- TODO !!!!!!!!!!!!!!!!!!!!
export const ADMINS = [
	'0xDE33d58193Cdec2a6383649353A2bFbFB037c4ed'
	, '0x4E2f6cE4D448d682AB0a7D6d88505179aFDba838'
	, '0x6462d1e470882b44E7cDD7D4CE892b40740D411C'
	, '0xc548D809C22a561c1BE9072D77A9B78CF6b8519F'
];
