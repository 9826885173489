import { Alert, AlertDescription, AlertIcon, Box, IconButton, VStack, useToast } from '@chakra-ui/react';
import { CellProps, WSysTable, WSysVert, WSysVertGroup, useWSysPage, useWSysPath, useWSysRemoteAny } from "@coimbra-its/websys-ux-core";
import CopyToClipboard from "react-copy-to-clipboard";
import { APPICONS } from '../Icons';
import { SunBlock, SunButton, SunLinkButton, SunPage, SunPage0, SunStack, SunValue, dispAddress, dispYearMonth, extractTxError, formatAmount } from "../components/Sun";
import { ISunPayout, SunState, useSunStore } from '../store/sunSlice';
import { useEffect, useState } from 'react';

const CELLS: CellProps<ISunPayout>[] = [
    { title: 'Date', elem: row => dispYearMonth(row.date) },
    //{ title: 'Status', elem: row => row.status },
    { title: 'Amount', elem: row => formatAmount(row.userAmount) },
]

export function Withdraw() {

    const path = useWSysPath();

    const page = useWSysPage({ title: 'Withdraw', maxCols: 2 });

    const connectedWallet = useSunStore((state: SunState) => state.connectedWallet);
    const payoutInfo = useSunStore(state => state.payoutInfo);
    const loadPayouts = useSunStore(state => state.loadPayouts);
    const loadPayoutsRemote = useSunStore(state => state.loadPayoutsRemote);
    const setRemote = useSunStore(state => state.setLoadPayoutsRemote);
    const loadBalance = useSunStore(state => state.loadBalance);


    const toast = useToast();

    const doLoadPayouts = async () => {
        await loadPayouts();
        toast({ status: 'success', description: 'payouts loaded' })
    }

    useEffect(() => {
        if (connectedWallet)
            loadPayouts();
    }, [connectedWallet])

    const doWithdraw = async () => {
        try {
            setRemote({ isLoading: true, error: '', isError: false });

            await connectedWallet?.PayoutContract.withdraw();
            await loadPayouts();
            await loadBalance();

        } catch (ex) {
            setRemote({ error: extractTxError('' + ex), isError: true });
        } finally {
            setRemote({ isLoading: false });
        }

    }

    return <SunPage page={page} remotes={[loadPayoutsRemote]} >
        <SunStack mb={4}>
            <SunBlock header='Sum payouts'>
                <SunValue dim='USDT'>{formatAmount(payoutInfo.userSum)}</SunValue>
            </SunBlock>
            <SunBlock header='Already withdrawn'>
                <SunValue dim='USDT'>{formatAmount(payoutInfo.userWithdrawn)}</SunValue>
            </SunBlock>
            <SunBlock header='Withdraw now' py={3}>
                <SunButton variant='primary' onClick={doWithdraw} >{`Withdraw ${formatAmount(payoutInfo.withdrawAble)} USDT`}</SunButton>
            </SunBlock>


            <SunButton variant='outline' onClick={doLoadPayouts} >Reload payouts</SunButton>

        </SunStack>

        <WSysTable
            cells={CELLS}
            rows={payoutInfo.payouts}
        />

    </SunPage>
}