import { Alert, AlertDescription, AlertIcon, Box, IconButton, VStack, useToast } from '@chakra-ui/react';
import { useWSysPage } from "@coimbra-its/websys-ux-core";
import CopyToClipboard from "react-copy-to-clipboard";
import { APPICONS } from '../Icons';
import { SunBlock, SunPage, SunValue, dispAddress, formatAmount } from "../components/Sun";
import { SunState, useSunStore } from '../store/sunSlice';

export function Referral() {
    const page = useWSysPage({ title: 'Referral program', maxCols: 2 });

    const connectedWallet = useSunStore((state: SunState) => state.connectedWallet);
    const walletError = useSunStore((state: SunState) => state.walletError);
    const amounts = useSunStore((state: SunState) => state.walletAmounts);
    const toast = useToast();




    return <SunPage page={page}>

        <VStack alignItems='stretch' justifyContent='center' h='100%' gap={9} px='4em'>
            {walletError && <Alert status='error'>
                <AlertIcon /><AlertDescription>{walletError}</AlertDescription>
            </Alert>}
            {connectedWallet && <>

                <SunBlock header='Wallet Address'>
                    <Box flex='1'> {dispAddress(connectedWallet.address)}</Box>
                    <CopyToClipboard text={connectedWallet.address} onCopy={() => { toast({ description: 'Address copied to clipboard' }) }} >
                        <IconButton aria-label='copy to clipboard' icon={<APPICONS.Copy />} variant='link' color='orange' />
                    </CopyToClipboard>
                </SunBlock>

                <SunBlock header='Level 1 bonus'>
                    <SunValue dim='SDBN1'>{formatAmount(amounts.lvl1Bonus)}</SunValue>
                </SunBlock>

                <SunBlock header='Level 2 bonus'>
                    <SunValue dim='SDBN1'>{formatAmount(amounts.lvl2Bonus)}</SunValue>
                </SunBlock>

                <SunBlock header='Level 3 bonus'>
                    <SunValue dim='SDBN1'>{formatAmount(amounts.lvl3Bonus)}</SunValue>
                </SunBlock>


            </>}

        </VStack>

    </SunPage>
}