import { Portal } from '@chakra-ui/react';
import { MutableRefObject, PropsWithChildren, ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { TWSysLayoutPageMode } from '../store/coreSlice';
import { WS_PAGE_STYLES } from './Layout.TODO';
import { WSysPathContext } from './Path';
import { WSysLog, WSysLogSeverity } from './utils.log';
import { getStoreCore } from './utils';



const log = WSysLog.asSource({ name: 'LAYOUT', dark: '#ee7', darkFont: 'black', light: '#ee7' }, (sev) => sev >= WSysLogSeverity.INFO || getStoreCore().layout.dumpLayout );
export const LayoutLog = log;



export type TWebSysPagePlaceRelation = 'backward' | 'self' | 'forward'; // klikkelésnél kire klikkeltek: rám, mögém vagy gyerekemre

// ----------------------------------------------- PROPS INTERFACES -------------------------------------------------
export interface WSysLayoutPagePropsIn {
	parentKey?: string;
	minCols: number;
	maxCols: number;
	title: string;
	order: number;
	isEdited: boolean;
	variant: 'attach' | 'main' | 'popup';
	wsStyle: keyof typeof WS_PAGE_STYLES;
	toolTokens?: string[];
}

export interface WSysLayoutPageProps extends PropsWithChildren<Partial<WSysLayoutPagePropsIn>> {
	title: string; // required here as well
	onClosed?: (() => void) | false;
}

// 

export interface WSysLayoutPagePropsOut {
	// ----- technical -----
	key: string;
	childPages: WSysPageNode[];


	// ----- calculated -----
	mode: TWSysLayoutPageMode;
	widthCols: number;
	maxHeightPx: number;
	isClosing: boolean;
	isBehindEdited: boolean;
	layerKind: 'full' | 'popup';
	visibleOnLayer: 'visible' | 'scrolled-out' | 'hidden-child' | 'covered';
	isParentVisible: boolean;
	layerKey: string;
	isHiddenEdited: boolean;
	isVisible: boolean;
	showToolTokens: string;
}

export interface WSysPageNode {
	propsIn: WSysLayoutPagePropsIn;
	propsOut: WSysLayoutPagePropsOut;
	portalElem: HTMLDivElement | null;
	onChange: () => void;
	onClosed?: () => void;
	close: () => void;

}

// ---------------------- Layout context ----------------------
export interface IWSysLayoutContext { manager?: WSysLayoutManager, page?: WSysPageNode, isMobileFull : boolean
		, widthCols : number };

export const WSysLayoutContext = createContext<IWSysLayoutContext>({ 
		isMobileFull: true,
		widthCols : 1
	});

export function useWSysLayoutContext(): Required<IWSysLayoutContext> {
	const ctx = useContext(WSysLayoutContext);
	if (!ctx.manager)
		throw new Error(`No manager in useWSysLayoutContext()`);
	return ctx as Required<IWSysLayoutContext>;
}


// ============================================ WEBSYS MANAGER ============================================

export class WSysLayoutManager {
	private __nodeIx: number = 0;
	private nodesByKey = new Map<string, WSysPageNode>();

	private roots: Array<WSysPageNode> = [];

	private _nodesChanged = new Set<WSysPageNode>();
	private calculate: ((reason: string, roots: Array<WSysPageNode>, nodesByKey: Map<string, WSysPageNode>) => void) = () => { };
	public setCalculate(calc: (reason: string, roots: Array<WSysPageNode>, nodesByKey: Map<string, WSysPageNode>) => void) {
		if (this.calculate !== calc) {
			this.calculate = calc;
			this.callCalculate('calc function changed');
		}
	}

	private callCalculate(reason: string) {
		this._nodesChanged.clear();
		this.calculate(reason, this.roots, this.nodesByKey);
		log.debug('.... calc start')();
		for (let node of Array.from(this._nodesChanged.values())) {
			log.debug('.... changed:', node.propsOut.key)();
			this.callOnChange(node);
		}
	}

	private callOnChange(node: WSysPageNode) {
		if (node.onChange) {
			requestAnimationFrame(() => {
				node.onChange!();
			});
		}
	}

	// -----------------
	public setPagePortal(key: string, elem: HTMLDivElement | null) {
		const pg = this.nodesByKey.get(key);
		if (!pg)
			return;
		if (elem && (!pg.portalElem || (pg.portalElem !== elem))) {
			log.debug('set portal elem on ', pg.propsIn.title, '(' + key + ')')();
			pg.portalElem = elem;
			this.callOnChange(pg);
		}
	}


	// ---------------------- Page Register ----------------------
	public useWSysPage = (propsIn: WSysLayoutPagePropsIn, onChange: () => void, onClosed?: () => void) => {
		const [key, setKey] = useState<string>('');
		const page: MutableRefObject<WSysPageNode> = useMemo(() => ({ current: null as any }), []);

		// ---------------------------- egyszer meghívott valódi regisztráció --------------------
		if (!page.current) {
			const newKey = 'node#' + this.__nodeIx;
			setKey(newKey);
			this.__nodeIx++;

			let __propsOut: WSysLayoutPagePropsOut = {	// ---- TODO: defaults ----
				key: newKey,
				childPages: [],

				mode: 'LASTONLY',
				widthCols: 1,
				isClosing: false,
				isBehindEdited: false,
				isHiddenEdited: false,
				isParentVisible: true,
				isVisible: true,
				layerKind: 'full',
				visibleOnLayer: 'visible',
				layerKey: '',
				maxHeightPx: 100,
				showToolTokens: '',
			};

			const __close = () => {
				if (!page.current.onClosed)
					return;
				page.current.propsOut.isClosing = true;
				forEachWSysPageChild(page.current.propsOut.childPages, p => p.propsOut.isClosing = true);
				//setPagesArr([...pagesArr]);
				this.callCalculate('close')
				setTimeout(() => {
					page.current.onClosed!();
				}, 300)
			}

			page.current = {
				propsIn,
				onChange,
				onClosed,
				close: __close,
				portalElem: null,
				propsOut: __propsOut,
			};

			const thisManager = this;
			page.current.propsOut = new Proxy(__propsOut, {
				set(target, name, newVal, receiver) {

					if ((target as any)[name] !== newVal) {
						log.debug('proxy', name, newVal)();
						thisManager._nodesChanged.add(page.current);
						(target as any)[name] = newVal;

					}
					return true;
				}
			});
		}

		// ---------------------------- egyszer meghívott valódi regisztráció 2 --------------------
		useEffect(() => {

			let parentPage: WSysPageNode | undefined;
			if (propsIn.parentKey) {
				parentPage = this.nodesByKey.get(propsIn.parentKey);
				if (parentPage) {
					parentPage.propsOut.childPages.push(page.current)
				}
			} else {
				this.roots.push(page.current);
			}
			this.nodesByKey.set(key, page.current);
			this.callCalculate(propsIn.title + ' register');
			//this.callOnChange(page.current);

			// ---------------------- unRegister -------------------------
			return () => {
				if (!this.nodesByKey.has(key))
					return;

				log.debug('remove ', '"' + page.current.propsIn.title + '"')();

				// -- remove from parent's childPages --
				let parentChildren = this.roots;
				if (parentPage)
					parentChildren = parentPage.propsOut.childPages as WSysPageNode[];
				let ix = parentChildren.indexOf(page.current);
				if (ix >= 0)
					parentChildren.splice(ix, 1);

				// -- remove --
				this.nodesByKey.delete(key);
				forEachWSysPageChild(page.current.propsOut.childPages, pg => {
					log.debug('... remove child ', '"' + pg.propsIn.title + '"')();
					this.nodesByKey.delete(pg.propsOut.key)
				});


				// -- trigger --
				this.callCalculate(propsIn.title + ' unregister');
			}



			// nyipog a missing dependency miatt (__pageIx, dispatch, title) de igazából nem kell, sőt fontos, hogy ez egyszer fusson csak le!
			// eslint-disable-next-line
		}, [key]);


		// ---------------------------- inProps változásfigyelés --------------------
		useEffect(() => {
			let changed = '';
			const node = this.nodesByKey.get(key);
			if (!node)
				return;
			const oldPropsIn = node.propsIn;

			for (let k in propsIn) {
				if ((propsIn as any)[k] !== (oldPropsIn as any)[k]) {
					changed += `${k} (${(oldPropsIn as any)[k]} -> ${(propsIn as any)[k]})`;
					(oldPropsIn as any)[k] = (propsIn as any)[k];
				}
			}
			if (changed)
				this.callCalculate(propsIn.title + ' changed : ' + changed);


			// eslint-disable-next-line
		}, Object.values(propsIn));

		/*useEffect(() => {
			if (page.current) {
				page.current.mutableRefs.onClose = handlers.onClose;
				page.current.mutableRefs.onChange = handlers.onChange;
			}
		}, [page.current, ...[Object.values(handlers)]]);
		*/


		return page.current;
	} // page Register
}







// ======================================================================================================= LAYOUT PAGE ============================
export function useWSysPage({ onClosed, order, ...propsIn }: WSysLayoutPageProps) {
	const px = useContext(WSysPathContext);
	if (px && typeof onClosed !== 'function' && onClosed !== false) {
		onClosed = px.parent.close.bind(px.parent);
	}
	if (px && typeof order !== 'number')
		order = px.parent.order;

	log.debug(`---  render "${propsIn.title}" ---`, px ? 'path: "' + px.parent?.pattern + '"' : '(no path)')();

	const { manager, page: parentPage } = useWSysLayoutContext();

	const triggerRef = useRef(0);
	const [trigger, setTrigger] = useState(triggerRef.current);

	const page = manager.useWSysPage({
		title: propsIn.title,
		minCols: propsIn.minCols || 1,
		maxCols: propsIn.maxCols || 1,
		order: typeof order === 'number' ? order : -1,
		isEdited: !!propsIn.isEdited,
		variant: propsIn.variant || 'main',
		wsStyle: propsIn.wsStyle || 'default',
		parentKey: parentPage?.propsOut?.key,
		toolTokens: propsIn.toolTokens,
	}, () => {
		setTrigger(++triggerRef.current)
		log.debug('PAGE RENDER TRIGGERED', page.propsIn.title, ' cols:', page.propsOut.widthCols)();
	}
		, onClosed || undefined);

	return page;
}

export function WSysLayoutPage({ children, page }: { children: ReactNode, page: WSysPageNode}) {
	const parentCtx = useContext(WSysLayoutContext);

	const ctx = useMemo(() => ({
		manager : parentCtx.manager, 
		page, 
		widthCols: page.propsOut.widthCols, 
		isMobileFull: parentCtx.isMobileFull,
	}), [parentCtx.manager, page, page.propsOut.widthCols, parentCtx.widthCols, parentCtx.isMobileFull]);

	/*const triggerRef = useRef(0);
	const [trigger, setTrigger] = useState(triggerRef.current);
	useEffect(()=> {
		setTrigger(++triggerRef.current);
		log.info('TRIGGER 2 ', page.propsOut.widthCols)();
	}, Object.values(page.propsOut))*/

	if (!page || !page.portalElem)
		return <></>;

	return <WSysLayoutContext.Provider value={ctx} >
		<Portal containerRef={{ current: page.portalElem! }} >
			{children}
		</Portal>
	</WSysLayoutContext.Provider>
}


export function forEachWSysPageChild(nodes: Array<WSysPageNode>, cb: (node: WSysPageNode) => void) {
	for (let n of nodes) {
		cb(n);
		forEachWSysPageChild(n.propsOut.childPages, cb);
	}
}		
