import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, BoxProps, Button, CircularProgress, useColorModeValue } from "@chakra-ui/react";
import { WSysUseQueryResult, WSysUseMutationResult, WSysUseResult, exctractErrorMessage } from "./utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";


export interface WSysRemoteProps extends BoxProps {
    remotes?: Array<WSysUseResult<any>>;
}

export function WSysRemote({children, remotes} : WSysRemoteProps) {
	const isLoadingMutation = !!remotes && !!remotes.find(rem => (!!(rem as WSysUseMutationResult<any>).reset && rem.isLoading));
	const isLoadingQuery = !!remotes && !!remotes.find(rem => (!(rem as WSysUseMutationResult<any>).reset && (rem.isLoading || (rem as WSysUseQueryResult<any>).isFetching)));
	const [isLoadingDebounced, setIsLoadingDebounced] = useState(false);
	//console.log(isLoadingQuery, isLoadingDebounced)
	const timer = useRef<any>(null);
	if (isLoadingQuery) {
		if (!isLoadingDebounced && !timer.current) {
			timer.current = setTimeout(() => {
				setIsLoadingDebounced(true);
				timer.current = null;
			}, 750);
		}
	} else {
		if (timer.current) {
			window.clearTimeout(timer.current);
			timer.current = null;
		}
		if (isLoadingDebounced)
			setIsLoadingDebounced(false);
	}

	const isError = !!remotes && remotes.reduce((prev, rem) => prev || rem.isError, false);

	const onCloseError = () => {
		if (!remotes) return;
		for (let rem of remotes) {
			if ((rem as WSysUseMutationResult<any>).reset)
				(rem as WSysUseMutationResult<any>).reset();
		}
	}

    return <>
        {children}
		{(isLoadingMutation || isLoadingQuery || isError) && <Box className="ws-panel-remote-backdrop" position='absolute'
			left={0} right={0} top={0} bottom={0} zIndex={1} bg={useColorModeValue('#fff6', '#0006')} transition='background-color .4s ease-out'
			display='flex' flexDir='column' alignItems='center' justifyContent='center'>
			{!isError && (isLoadingMutation || isLoadingDebounced) && <CircularProgress isIndeterminate color='primary.main' />}
			{isError && <Alert status="error" maxW='60%' flexDir='column' boxShadow='md' p={2} 
				//bg='var(--chakra-colors-chakra-body-bg)'  
			>
				<Box display='flex'>
					<AlertTitle>Szerver válasz:</AlertTitle>
				</Box>
				<AlertDescription display='flex' flexDirection='column' alignItems='center' gap={3} pt={3}  >

					{remotes.filter(rem => rem.isError).map((rem, rix) => <Box key={rix}>
						<AlertIcon display='inline-block' />{exctractErrorMessage(rem.error)}
					</Box>)}
					<Button variant='solid' colorScheme="red" onClick={onCloseError}>Ok</Button>
				</AlertDescription>

			</Alert>}
		</Box>}        
    </>
}


/// =================================================== ANY ===============================================
export function useWSysRemoteAny() {
	const [isLoading, _setIsLoading] = useState(false);
	const [error, _setError] = useState<FetchBaseQueryError | undefined>();

	const setIsLoading = useCallback((v : boolean) => {
		_setIsLoading(v);
		if (v)
			_setError(undefined);
	}, [_setIsLoading]);

	const setError = useCallback((error : FetchBaseQueryError | string | undefined) => {
		if (typeof error === 'string')
			_setError({status:400, data: { publicMessage : error }});
		else 
			_setError(error);
	}, [_setError]);


	const reset = useCallback(() => {
		setError(undefined);
		setIsLoading(false);
	}, []);

	useEffect(() => {
		if (error && isLoading)
			setIsLoading(false);
	}, [isLoading, error]);


	return useMemo(() => {
		return {
			isLoading, isError: !!error, reset, error,
			setIsLoading, setError
		}
	}, [isLoading, error, reset]);

}