import { Box, BoxProps, Button, IconButton, Table, Tbody, Td, Th, Thead, Tr, useBoolean } from "@chakra-ui/react";
import { ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { CellProps, IEntity } from "./CellMeta";
import { useSelector } from "react-redux";
import { IWebSysFormSubRow, SysFormRowsRef } from "./Form";
import { WSysField } from "./Input";
import { IRootStateCore, useWSysPreferences } from "../utils";
import { ClassValidator } from "@coimbra-its/websys-lib";
import { ICONS } from "../theme";
import { WSysLayoutContext } from "../LayoutManager";
import { LAYOUT_COLUMN_WIDTH_PX, WSYS_TABLE_HOVER_STYLE, WSYS_TABLE_SELECTION_STYLE } from "../Layout.TODO";
import { WSysStickyBox } from "../StickyBox";


export const CHAR_WIDTH_PX = 12;
const SPAN_PER_COL = 3;
const CAPTION_WIDTH_COLS = 1;




// internally used 
interface WSysCellItem<T extends IEntity> {
	cellProps: CellProps<T>;
	title: string;
	width: string;
	colSpan?: number;
	kind?: 'caption' | 'title' | 'id' | 'sub';
	showCaption: boolean;
}

export interface WSysTableProps<TRow extends IEntity> extends Omit<BoxProps,'title'> {
	rows: Array<TRow> | undefined;
	cells: Array<CellProps<TRow>>;

	footer?: ReactNode;
	selectedRow?: TRow;
	onRowSelect?: (row: TRow) => void;
	onRowDelete?: (row: TRow) => void;
	isForm?: boolean;
	title?: ReactNode
}

export function WSysTable<TRow extends IEntity>({ rows, cells, selectedRow, onRowSelect, onRowDelete, title, footer, isForm = false, ...rest }: WSysTableProps<TRow>) {

	const pageCtx = useContext(WSysLayoutContext);
	const sysSetting = useSelector((state: IRootStateCore) => state.core);
	const dump = true;//TODO: sysSetting.layout.dumpTable;

	const preferences = useWSysPreferences();

	const [mode, setMode] = useState<'TABLE' | 'MULTILINE-TABLE'>(pageCtx.widthCols >= 5 ? 'TABLE' : 'MULTILINE-TABLE');
	const [cellItems, setCellItems] = useState<Array<WSysCellItem<TRow>>>([]);
	const [multiLine, setMultiLine] = useState<Array<Array<WSysCellItem<TRow>>>>([]);
	const [forceTable, setForceTable] = useBoolean(true);


	const tools = <Box bg='primary.600'>
		<IconButton size="xs" variant='link' colorScheme="yellow" icon={<ICONS.Preferences />} aria-label="tábla beállítások"
			onClick={setForceTable.toggle}
		/>
	</Box>;

	useEffect(() => {
		const _cellItems: typeof cellItems = [];
		const fullWidthTd = pageCtx.widthCols * SPAN_PER_COL;
		let cellSumWidthTd = 0;
		for (let cellProps of cells) {

			let title = cellProps.title || cellProps.field;
			let width = cellProps.width || (cellProps.charWidth && ((cellProps.charWidth * CHAR_WIDTH_PX) + 'px')) || '99px';

			_cellItems.push({ cellProps, title: title || '?', width, showCaption: false });
			let widthPx = parseInt(width.replace('px', '')) || 100;
			cellSumWidthTd += Math.min(fullWidthTd, Math.ceil(widthPx / (LAYOUT_COLUMN_WIDTH_PX / SPAN_PER_COL)));

		}
		setCellItems(_cellItems);

		const _mode = forceTable || (cellSumWidthTd <= fullWidthTd * 2) ? 'TABLE' : 'MULTILINE-TABLE';
		setMode(_mode);

		if (_mode === 'MULTILINE-TABLE') {
			const _multiline: typeof multiLine = [];
			if (dump) console.log('%c------ calc cells ------', 'color:cyan', fullWidthTd);
			let remainingTd = fullWidthTd;
			let row = new Array<WSysCellItem<TRow>>();
			let firstCell = true;
			let firstLine = true;
			for (let cellItem of _cellItems) {
				let widthPx = parseInt(cellItem.width.replace('px', '')) || 100;
				cellItem.colSpan = Math.min(fullWidthTd, Math.ceil(widthPx / (LAYOUT_COLUMN_WIDTH_PX / SPAN_PER_COL)));
				if (dump) console.log('%c' + cellItem.title, 'color:blue', widthPx, ' -> ', cellItem.colSpan);
				cellItem.showCaption = !firstLine && !cellItem.cellProps.cardNoCaption;
				let widthTdWithCaption = Math.min(fullWidthTd, cellItem.colSpan + (cellItem.showCaption ? CAPTION_WIDTH_COLS : 0));
				if (remainingTd < widthTdWithCaption) {
					let last = row[row.length - 1];
					last.colSpan! += remainingTd;
					_multiline.push(row);
					row = new Array<WSysCellItem<TRow>>();
					remainingTd = fullWidthTd;
					firstLine = false;
				}

				cellItem.showCaption = !firstLine && !cellItem.cellProps.cardNoCaption;
				widthTdWithCaption = Math.min(fullWidthTd, cellItem.colSpan + (cellItem.showCaption ? CAPTION_WIDTH_COLS : 0));
				cellItem.colSpan = widthTdWithCaption - (cellItem.showCaption ? CAPTION_WIDTH_COLS : 0);
				cellItem.kind = firstCell ? 'title' : undefined;

				if (cellItem.showCaption) {
					row.push({ ...cellItem, colSpan: CAPTION_WIDTH_COLS, kind: 'caption' });
				}
				row.push(cellItem);
				remainingTd -= widthTdWithCaption;
				firstCell = false;
			}
			if (row.length > 0) {
				let last = row[row.length - 1];
				last.colSpan! += remainingTd;
				_multiline.push(row);
			}
			setMultiLine(_multiline);
		}
	}, [pageCtx.widthCols, forceTable, isForm, cells]);

	//const [hoverRow, setHoverRow] = useState<TRow | null>(null)
	const onMouseEnter = (row: TRow) => {
		//setHoverRow(row);
	}

	if (mode === 'MULTILINE-TABLE') { // ------------------------------------------ MULTILINE --------------------------------------
		//TODO: return <WebSysBlock slot="table" {...rest} tools={tools}	>
		return <Box {...rest}>
			{title}
			<Table size='sm' variant='unstyled' whiteSpace='nowrap' css={{ tableLayout: 'fixed' }} width='100%' overflowX='hidden'   >
				<Thead position="sticky" top={`0px`} zIndex={1}  >
					{multiLine.filter((line, lix) => lix <= 0).map((line, lix) => <Tr key={lix} bg={lix === 0 ? preferences.colors.tableHead.value : 'background.400'} >
						{line.map((c, cix) => <Th key={cix} colSpan={c.colSpan}  >{c.title}</Th>)}
						{onRowDelete && lix === 0 && <Th width='50px'  >&nbsp;</Th>}
					</Tr>)}
				</Thead>
				<Tbody>
					{(rows || []).map((row, ix) =>
						multiLine.map((line, lix) => <Tr key={lix} cursor='pointer'
							//_hover={{ bg: 'blackAlpha.50' }}
							bg={selectedRow === row ? (lix === 0 ? 'bgAlpha.100' : 'bgAlpha.100') : (lix === 0 ? '' : '')}
							{...lix === 0 && { borderBottom: '1px solid #0005' }}
							//{...selectedRow === row && { bg: 'bgAlpha.200', _hover: { bg: 'bgAlpha.300' } }}
							onClick={e => { if (onRowSelect) onRowSelect(row) }}
							onMouseEnter={() => onMouseEnter(row)}

						>
							{line.map((cell, iy) => <Td key={iy} colSpan={cell.colSpan}
								{...cell.kind === 'caption' && { textAlign: 'right', color: 'blackAlpha.600' }}
								{...cell.kind === 'title' && { fontWeight: 'semibold', fontSize: '1em' }}
								//{...cell.kind === 'title' && selectedRow !== row && /*hoverRow !==row  &&*/ { bg: 'bgAlpha.100' }}
								{...lix === multiLine.length - 1 && rows && { paddingBottom: '35px', borderBottom: (ix < rows.length - 1 ? '8px' : '3px') + ' solid #0000004a' }}
								{...cell.cellProps.field === 'MEGJ' && { color: 'primary.700', fontStyle: 'italic' }}
							>
								{cell.kind === 'caption' ? cell.title + ': ' : cell.cellProps.elem(row)}
							</Td>)}
							{onRowDelete && lix === 0 && <Td pl={0}><IconButton aria-label="sor törlése" icon={<ICONS.DeleteRow />} onClick={() => onRowDelete(row)} size='sm' /></Td>}

						</Tr>))
					}
				</Tbody>
			</Table>
			{footer}
		</Box>
	}

	const ColGorup = <colgroup>
		{onRowDelete && <col style={{ width: '35px' }} />}
		{cellItems.map((c, cix) => <col key={cix} style={{ width: c.width }} />)}
	</colgroup>;

	//TODO: return <WebSysBlock slot="table" {...rest} tools={tools} >
	return <WSysStickyBox
			title={title}
			childMinHeight="200px"
			head={
				<Table size='sm' variant='simple' css={{ tableLayout: 'fixed' }} whiteSpace='nowrap'  >
					{ColGorup}
					<Thead position="sticky" top={`0px`} zIndex={1} bg={preferences.colors.tableHead.value} >
						<Tr >
							{onRowDelete && <Th width='35px' px={0}>&nbsp;</Th>}
							{cellItems.map((c, cix) => <Th key={cix} width={c.width} >{c.title}</Th>)}
						</Tr>
					</Thead>
				</Table>
			}
		>
			<Table size='sm' variant='simple' css={{ tableLayout: 'fixed' }} whiteSpace='nowrap'  >
				{ColGorup}
				<Tbody>
					{(rows || []).map((row, ix) =>
						<Tr key={ix} cursor='pointer'
							_hover={WSYS_TABLE_HOVER_STYLE}
							{...selectedRow === row && WSYS_TABLE_SELECTION_STYLE}
							
							onClick={e => { if (onRowSelect) onRowSelect(row) }}
						>
							{onRowDelete && <Td px={1} display='flex' justifyContent='center' alignItems='center'><IconButton aria-label="sor törlése" icon={<ICONS.DeleteRow />} onClick={() => onRowDelete(row)} size='sm' /></Td>}
							{cells.map((cell, iy) => <Td {...isForm && { p: 1 }} key={iy}>{cell.elem(row)}</Td>)}

						</Tr>)}
				</Tbody>
			</Table>
		</WSysStickyBox>
		//{footer}

}


// ============================================== FORM TABLE ========================================
export interface WSysFormCellProps<TRow extends IEntity> extends Omit<CellProps<TRow>, 'elem'> {
	elem: ((row: IWebSysFormSubRow<TRow>) => ReactNode) | ReactNode;
	field: keyof TRow & string;
}

export interface WSysFormTableProps<TRow extends IEntity> extends BoxProps {
	cells: Array<WSysFormCellProps<TRow>>;
	readonlyCells: Array<CellProps<TRow>>;
	formRef?: SysFormRowsRef<TRow>;
	isEdited?: boolean;
	rows?: Array<IWebSysFormSubRow<TRow>> | undefined;
	meta?: ClassValidator<TRow>;
}




export function WSysFormTable<TRow extends IEntity>({ rows: pRows, cells, meta: pMeta, formRef, isEdited, readonlyCells, ...rest }: WSysFormTableProps<TRow>) {
	const meta = pMeta || formRef?.meta;
	const rows = pRows || formRef?.rows;

	const onRowDelete = useCallback((row: IWebSysFormSubRow<TRow>) => {
		row.formRow$.remove();
	}, [formRef]);

	const xCells: Array<CellProps<IWebSysFormSubRow<TRow>>> = cells.map(cell => {
		const column = meta?.Column(cell.field);
		return {
			elem: (row) => (typeof cell.elem === 'function')
				? <WSysField<TRow> noTitle={false} formRow={row} field={cell.field} children={cell.elem(row)} />
				: <WSysField<TRow> noTitle={false} formRow={row} field={cell.field} children={cell.elem} />,
			title: cell.title || column?.caption,
			charWidth: cell.charWidth || column?.charWidth,
			field: cell.field,
		} as CellProps<IWebSysFormSubRow<TRow>>
	});

	let footer: ReactNode = false;
	if (formRef && isEdited) {
		footer = <Box display='flex' flexDir='row' alignItems='center' justifyContent='flex-start' p={2} >
			<Button size='sm' variant='solid' colorScheme="primary" onClick={() => { formRef.createRow() }} leftIcon={<ICONS.Create />} >Új sor</Button>
		</Box>
	}

	//meta={meta as any as ClassValidator<IWebSysFormRow<TRow>>}
	return isEdited
		? <WSysTable key='a' rows={rows} cells={xCells} footer={footer} onRowDelete={isEdited ? onRowDelete : undefined} isForm {...rest} />
		: <WSysTable key='a' rows={rows?.map(r => r.data)} cells={readonlyCells}  {...rest} />
}