
import { Box, Img } from '@chakra-ui/react';
import { WSysLayout, WSysPreferences, useWSysPath } from "@coimbra-its/websys-ux-core";
import "@fontsource/montserrat";
import { Dashboard } from './features/Dashboard';
import { Wallet } from './features/Wallet';
import { useSunStore } from './store/sunSlice';
import { useWeb3 } from './web3/web3';
import { Referral } from './features/Referral';
import { Withdraw } from './features/Withdraw';
import { Payout } from './features/Payout';
import { SunBlock } from './components/Sun';



export function App() {

	const path = useWSysPath();
	const web3 = useWeb3();

	const connectedWallet = useSunStore((state) => state.connectedWallet)
	const isLoading = useSunStore((state) => state.isLoading)

	const walletBox = <SunBlock py={3} {...!!connectedWallet && {header:'Connected wallet'}}>
			<w3m-button />
		</SunBlock>


	return <>
		<Box position='fixed' left='50px' top='44px' color='#fff'>
			<Img src='images/sunmoney_logo.png' w='121px' /><br />
		</Box>
		<WSysLayout >
			{path.on('wallet', <Wallet />)}
			{path.on('referral', <Referral />)}
			{path.on('withdraw', <Withdraw />)}
			{path.on('payout', <Payout />)}
			{path.on('preferences', <WSysPreferences />, { group: 99 })}
			{path.elseOpen(<Dashboard walletBox={walletBox} />)}

		</WSysLayout>

	</>
}