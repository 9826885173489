import { ChakraProvider, extendTheme, theme } from "@chakra-ui/react";
import { IWSysCorePreferences, coreSlice } from "../store/coreSlice";
import { ReactNode, useEffect, useState } from "react";
import { useWSysPreferences } from "./utils";
import chroma from "chroma-js";
import { IconType } from "react-icons";
import { useDispatch } from "react-redux";
import { mergeDeep } from "@coimbra-its/websys-lib";

export const LIGHTNESSES = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

function getColorScheme(main: string) {
	const mainHsl = chroma(main).hsl();
	const arr: any = { main };
	for (let l of LIGHTNESSES) {
		let c = chroma(mainHsl[0], mainHsl[1], 1 - (l / 1000), 'hsl').hex();
		arr[l] = c;
	}
	return arr;
}

function createTheme(preferences: IWSysCorePreferences, appCustomChakraTheme: any): Record<string, any> {

	let bgProps = {
		//backgroundColor: '#eee'
	};


	let baseColorHsl = chroma(preferences.baseColor).hsl();
	let primary: any = {
		main: preferences.baseColor
	};
	for (let l of LIGHTNESSES) {
		let c = chroma(baseColorHsl[0], baseColorHsl[1], 1 - (l / 1000), 'hsl').hex();
		primary[l] = c;
	}
	const outlineColor = primary['800'] + '53';

	let chakraTheme = {
		initialColorMode: 'dark',
		useSystemColorMode: false,
		styles: {
			global: {
				'body': {
					//background: bodyColor,
					//color: '#000a',
					minHeight: '100vh',
					height: '100vh',
					p: 0, m: 0,
					overscrollBehaviorY: 'contain',
					...bgProps
				},
				'.wsys-set-portal-height-full > .chakra-portal': {
					height: '100%'
				},
				/*'.wsys-height-full .wsys-block': {
					height: '100%'
				}*/
			}
		},

		colors: {
			primary,
			/*background,
			bgAlpha,
			alert,*/
			wsysLine: '#2c6195'
		},
		components: {
			FormLabel: {
				baseStyle: {
					//color: 'primary.800',
					fontWeight: '400',
					fontSize: '.75em',
					marginTop: '11px',
				}
			},

			Table: {
				parts: ['th', 'td'],
				baseStyle: {
					th: {
						textTransform: 'none',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},
					td: {
						overflow: 'hidden',
						textOverflow: 'ellipsis'
					},
				},
				variants: {
					simple: {
						th: {
							//color: '#fffc',
						}
					},
					unstyled: {
						th: {
							//color: '#fffc',
						}
					}
				}
			},

			Input: {
				baseStyle: {
					field: {
						fontWeight: 'semibold',
						//color: '#1a202c',
						fontSize: 'md',
						//outline: `1px solid ${outlineColor}`
					},
				},
			},

			Textarea: {
				baseStyle: {
					//outline: `1px solid ${outlineColor}` 
				}
			},

			Select: {
				baseStyle: {
					field: {
						fontWeight: 'semibold',
						//color: '#1a202c',
						fontSize: 'md',
						//outline: `1px solid ${outlineColor}`
					},
				},
			},

			Button: {
				variants: {
					wsysHeadIconButton: (props:any) => ({
						...theme.components.Button.variants!.ghost(props),
					})
				}
			}

		},
	};

	if (appCustomChakraTheme) {
		chakraTheme = mergeDeep(chakraTheme, appCustomChakraTheme);
	}

	return extendTheme(chakraTheme);
}



// ====================================================================== ICONS ========================================================
export var ICONS: IWSysCoreIcons = {} as any;
export interface IWSysCoreIcons {

	Home: IconType;
	Close: IconType;
	Back: IconType;
	Cancel: IconType;
	Prev: IconType;
	Next: IconType;
	Save: IconType;
	Edit: IconType;
	Create: IconType;
	Delete: IconType;
	DeleteRow: IconType;
	Preferences: IconType;
	Check: IconType;
	CheckOff: IconType;
	Filter: IconType;
	FilterOff: IconType;
	Search: IconType;
}




// ====================================================================== APP THEME ========================================================
export interface AppThemeProps {
	icons: IWSysCoreIcons;
	preferencesCompanyDefault: string;
	children: ReactNode;
	appCustomChakraTheme?: any;
}

export function AppTheme({ icons, preferencesCompanyDefault, children, appCustomChakraTheme }: AppThemeProps) {
	const dispatch = useDispatch();

	ICONS = icons;
	preferencesCompanyDefault;
	if (!window.localStorage.getItem('PREFERENCES')) {
		//window.localStorage.setItem('PREFERENCES', preferencesCompanyDefault);
		dispatch(coreSlice.actions.preferencesSet(JSON.parse(preferencesCompanyDefault)));
	}
	const preferences = useWSysPreferences();

	const [theme, setTheme] = useState(createTheme(preferences, appCustomChakraTheme));
	useEffect(() => {
		setTheme(createTheme(preferences, appCustomChakraTheme));
	}, [preferences.baseColor, appCustomChakraTheme]);


	return <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: "bottom-right" } }} >{children}</ChakraProvider>
}