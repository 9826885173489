import {
	BsCheck as BsCheck2Square, //TODO
	BsSquare
} from 'react-icons/bs';
import {
	FaArrowLeft, FaArrowRight,
	FaExclamationCircle,
	FaFilter,
	FaUser
} from 'react-icons/fa';
import {
	MdAddCircleOutline,
	MdArrowBack,
	MdArrowForward,
	MdCancel,
	MdClose,
	MdEdit, //TODO
	MdHome,
	MdOutlineContentCopy,
	MdSave,
	MdSearch,
	MdSettings
} from 'react-icons/md';
import { RiCloseCircleFill } from 'react-icons/ri';

export const APPICONS = {
	Close : MdClose,
	Back : MdArrowBack,
	Cancel : MdCancel,
	Prev : MdArrowBack,
	Next : MdArrowForward,
	Save : MdSave,
	Edit : MdEdit,
	Search : MdSearch,
	Filter : FaFilter,
	FilterOff : MdCancel,
	Create : MdAddCircleOutline,
	Delete : RiCloseCircleFill,
	DeleteRow : MdClose,
	Home : MdHome,
	Preferences : MdSettings,
	Alert : FaExclamationCircle,
	Left : FaArrowLeft,
	Right : FaArrowRight,
	User : FaUser,
	Check : BsCheck2Square,
	CheckOff : BsSquare,
	Settings : MdSettings,



	Copy : MdOutlineContentCopy,
	/*Partner : FaUser,
	Paper : MdInsertDriveFile,
	Item : FaCircle,
	Raktar : FaWarehouse,
	Task : FaTasks,
	KeszletMozgas : FaTruck,
	Article : FaBox,
	Tasks : FaTasks,
	Attach : MdAttachFile,
	Grades : FaAddressBook
	*/
}